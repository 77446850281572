import React, { FC, useEffect, useState } from "react";
import { useModalWrapper } from "src/components/Modal/ModalWrapperContext";
import { KatButton, KatInput, KatPopover } from "@amzn/katal-react";
import { ValidationUserActionHandlerProps } from "src/components/UserActions/Handlers/Validation/validation_user_action_handler";
import { log, LogLevel } from "src/logger";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import styles from "src/components/UserActions/Handlers/Validation/OverrideMetadata/RelatedDocuments/styles.module.scss";
import { sanitizeAlphaNumericString } from "src/utils";
import { UserAction } from "src/components/UserActions/types";

function buildRelatedDocuments(
  value: string,
  userAction: UserAction | undefined
) {
  return value
    .split(",")
    .map((inputValue) => inputValue.trim())
    .map(
      (documentId) =>
        `${userAction?.metadata?.countryCode}::${userAction?.metadata?.documentType}::${documentId}`
    );
}

const RelatedDocumentEdit: FC<ValidationUserActionHandlerProps> = ({
  onCancel,
  onSubmit,
  userAction,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { setVisible } = useModalWrapper();
  const handleOnChangeValue = ({ target: { value: newValue } }: any) => {
    if (
      userAction?.metadata?.inputTransformationType &&
      userAction?.metadata?.inputTransformationType === "REGEX"
    ) {
      const regexPattern = RegExp(
        userAction?.metadata?.inputTransformationValue,
        "g"
      );
      setValue(sanitizeAlphaNumericString(newValue, regexPattern));
    } else {
      setValue(newValue);
    }
  };

  useEffect(() => {
    const documentIds = value.split(",");
    setIsDisabled(documentIds.length > 10);
  }, [value]);

  const update = () => {
    if (value && onSubmit) {
      onSubmit({
        relatedDocuments: JSON.stringify(
          buildRelatedDocuments(value, userAction)
        ),
      });
    } else {
      log({
        level: LogLevel.WARNING,
        message: "No request has been configured for this user action.",
        operationNamespace: "ValidationUserActions.RelatedDocumentEdit.update",
      });
    }
    setVisible(false);
  };

  return (
    <>
      <KatInput
        id="update-related-documents-input"
        value={value}
        onInput={handleOnChangeValue}
        type="text"
        label={t(
          userAction?.label ?? "aic_doc_page_related_documents_edit_label"
        )}
      />
      <div className={classnames(styles.actionContainer)}>
        <div className={classnames(styles.buttonContainer)}>
          <KatButton
            id="cancel-update-related-documents-button"
            onClick={onCancel}
            variant="secondary"
          >
            {t("aic_doc_page_related_document_edit_cancel")}
          </KatButton>
        </div>
        <div className={classnames(styles.buttonContainer)}>
          <KatPopover
            id="update-related-documents-popover"
            kat-aria-behavior="tooltip"
            position="left"
            triggerType="hover"
            disabled={!isDisabled}
          >
            <KatButton
              id="update-related-documents-button"
              onClick={update}
              slot="trigger"
              disabled={isDisabled}
            >
              {t("aic_validate_user_actions_update_and_restart")}
            </KatButton>
            {t("aic_validate_user_actions_info_override_related_documents")}
          </KatPopover>
        </div>
      </div>
    </>
  );
};

export default RelatedDocumentEdit;
