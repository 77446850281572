import { KatButton } from "@amzn/katal-react";
import React from "react";

import { useTranslation } from "react-i18next";
import { useDocumentList } from "../Contexts/DocumentList/Provider";
import dataTableDefinition from "../Pages/DocumentList/DocumentTable/document-listing-data-table-definition";
import { ColumnDefinition } from "src/components/DataTable/DataTableDefinition";

import { get } from "lodash";
import { DocumentField } from "src/constants";
import { getDocumentReferences } from "../Pages/DocumentList/DocumentTable/DocumentReferences";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import { getPartner } from "../Pages/DocumentList/DocumentTable/DocumentExternalPartner";
import { objectListToCsv } from "src/utils";
import { getDocumentId } from "../Pages/DocumentList/DocumentTable/DocumentId";

const ExportButton = () => {
  const { t } = useTranslation();
  const {
    state: { documents },
  } = useDocumentList();

  const buildReportList = () => {
    const documentList: Array<Record<string, string | undefined>> = [];

    Object.values(documents).forEach((document) => {
      const row: Record<string, string | undefined> = {};
      dataTableDefinition.columnsDefinition.forEach(
        (definition: ColumnDefinition) => {
          const consolidated: ConsolidatedDocument = {
            ...document,
            [DocumentField.DOCUMENT_ID]: document.documentId,
            [DocumentField.DOCUMENT_TYPE]: document.documentType,
            [DocumentField.COUNTRY_CODE]: document.countryCode,
            [DocumentField.STATUS]: document.status,
            [DocumentField.USE_CASE]: document.useCase,
            [DocumentField.REFERENCED_DOCUMENT]:
              document.documentDetails?.referencedDocuments,
            [DocumentField.ISSUANCE_DATE]:
              document.documentDetails?.issuanceDate,
            [DocumentField.OFFICIAL_DOCUMENT_ID]:
              document.documentDetails?.officialDocumentId,
            [DocumentField.EXTERNAL_PARTNERS]: document.externalPartners,
            [DocumentField.EXTERNAL_PARTNER]: null,
            [DocumentField.TOTAL_COST]:
              document.documentDetails?.documentTotalCost,
          };

          let value: string | undefined;
          switch (definition.property) {
            case DocumentField.REFERENCED_DOCUMENT.valueOf():
              value = getDocumentReferences(consolidated);
              break;

            case DocumentField.EXTERNAL_PARTNERS.valueOf():
              const partner = getPartner(consolidated);
              value = partner ? partner.title || partner.text : "";
              break;

            case DocumentField.DOCUMENT_ID.valueOf():
              // This is a pattern to avoid excel interpreting this value as a number when is a text with only numbers
              value = `\t"${getDocumentId(consolidated)}"`;
              break;

            default:
              if (definition.formatter) {
                value = definition.formatter(
                  get(consolidated, definition.property)
                ) as string;
              } else {
                value = get(consolidated, definition.property);
              }
              break;
          }
          row[t(definition.label)] =
            value?.replace(/,/g, " - ").replace(/\n/g, " ") || "";
        }
      );
      documentList.push(row);
    });
    return documentList;
  };

  const buildDownloadUrl = (
    objectToConvert: Array<Record<string, string | undefined>>
  ) => {
    const csv = objectToConvert.length ? objectListToCsv(objectToConvert) : "";

    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    return url;
  };

  const reportList: Array<Record<string, string | undefined>> =
    buildReportList();

  const downloadUrl: string = buildDownloadUrl(reportList);

  return (
    <a
      href={downloadUrl}
      download={`aic-search-result-export-${new Date().toISOString()}.csv`}
      onClick={() => setTimeout(() => URL.revokeObjectURL(downloadUrl), 1000)}
    >
      <KatButton disabled={!reportList.length}>
        {t("aic_doc_listing_export_button")}
      </KatButton>
    </a>
  );
};

export default ExportButton;
