import React, { FC } from "react";
import { getFromObject } from "src/utils";

import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import { ConsolidatedDocument } from "src/api/interfaces/document_listing";
import CopyToClipboardButton from "src/components/Pages/DocumentInbound/CopyToClipboardButton";

const OFFICIAL_DOCUMENT_ID = "documentDetails.officialDocumentId";
const DOCUMENT_ID = "documentId";

export const getDocumentId = (consolidatedDocument: ConsolidatedDocument) => {
  const officialDocumentId = getFromObject(
    consolidatedDocument,
    OFFICIAL_DOCUMENT_ID
  );

  if (officialDocumentId) return officialDocumentId;

  return getFromObject(consolidatedDocument, DOCUMENT_ID);
};

const DocumentId: FC<
  DataTableCellChildComponentProps<ConsolidatedDocument>
> = ({ content }) => {
  const documentId = getDocumentId(content);
  return (
    <p>
      {documentId}
      <CopyToClipboardButton
        label="document-id"
        translationPrefix="aic_doc_page_breadcrumb_document_list_document_id_popover"
        value={documentId}
      />
    </p>
  );
};

export default DocumentId;
