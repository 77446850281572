import React, { FC, useCallback, useContext, useMemo } from "react";
import { KatLink, KatTooltip } from "@amzn/katal-react";
import { DataTableCellChildComponentProps } from "src/components/DataTable/DataTableDefinition";
import {
  InboundDocument,
  InboundStatus,
} from "src/api/interfaces/inbound_document";
import styles from "../../../../../../styles.module.scss";
import { useTranslation } from "react-i18next";
import { RelatedDocumentsOverride } from "src/components/UserActions/Handlers/Validation/types";
import {
  DEFAULT_TRANSLATION_CONFIG,
  DEFAULT_TRANSLATION_NS,
} from "src/components/TranslatedField/TranslationKey";
import { InboundDocumentContext } from "src/components/Contexts/InboundDocumentContext";

export type DocumentIdCellProps =
  DataTableCellChildComponentProps<InboundDocument>;

const DocumentIdCell: FC<DocumentIdCellProps> = ({ content }) => {
  const inboundDocument = useContext(InboundDocumentContext);

  const href = useMemo(
    () =>
      `/inbound-central/countries/${content.countryCode}/document-types/${content.documentType}/documents/${content.documentId}`,
    [content]
  );

  const shouldGenerateLink = useCallback(() => {
    return content.status !== InboundStatus.NOT_INGESTED;
  }, [content]);

  const { t } = useTranslation(
    DEFAULT_TRANSLATION_NS,
    DEFAULT_TRANSLATION_CONFIG
  );

  const overriddenValues: RelatedDocumentsOverride | undefined =
    inboundDocument?.metadataOverrides;

  const shouldDisplayToolTip = overriddenValues?.relatedDocuments?.some(
    (relatedDocument) =>
      relatedDocument.split("::").pop() === content.documentId
  );

  return (
    <div>
      {useMemo(
        () =>
          shouldGenerateLink() ? (
            <KatLink
              label={content.documentId}
              target="_blank"
              href={href}
              rel="noreferrer"
            />
          ) : (
            content.documentId
          ),
        [content, shouldGenerateLink, href]
      )}
      {shouldDisplayToolTip ? (
        <KatTooltip
          label={t("aic_related_document_value_overridden_from_action")}
          trigger-icon="info-circle"
          position="right"
          size="tiny"
          className={styles.kat_component_bold_label}
        />
      ) : null}
    </div>
  );
};

export default DocumentIdCell;
