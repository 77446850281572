import { log, LogLevel } from "src/logger";

export enum MetricAction {
  CHANGE_LOCALE = "CHANGE_LOCALE",
  DOCUMENT_ITEMS_TAB_CLICK = "DOCUMENT_ITEMS_TAB_CLICK",
  DOCUMENT_ITEMS_TAB_SEARCHES = "DOCUMENT_ITEMS_TAB_SEARCHES",
  DOCUMENT_ITEMS_TAB_DETAILS_CLICK = "DOCUMENT_ITEMS_TAB_DETAILS_CLICK",
  DOCUMENT_ITEMS_TAB_EXPAND_ERRORED_LINE_ITEMS_CLICK = "DOCUMENT_ITEMS_TAB_EXPAND_ERRORED_LINE_ITEMS_CLICK",
  DOCUMENT_ITEMS_TAB_FILTER_RULE_SEVERITY_LINE_ITEMS_CLICK = "DOCUMENT_ITEMS_TAB_FILTER_RULE_SEVERITY_LINE_ITEMS_CLICK",
  DOCUMENT_HISTORY_TAB_CLICK = "DOCUMENT_HISTORY_TAB_CLICK",
  DOCUMENT_HISTORY_TAB_SEARCHES = "DOCUMENT_HISTORY_TAB_SEARCHES",
  DOCUMENT_HISTORY_TAB_PAGINATION = "DOCUMENT_HISTORY_TAB_PAGINATION",
  INBOUND_COMPLIANCE_TAB_CLICK = "INBOUND_COMPLIANCE_TAB_CLICK",
  REQUEST_HISTORY_PAYLOAD_SIZE = "REQUEST_HISTORY_PAYLOAD_SIZE",
  REQUEST_LINE_ITEMS_PAYLOAD_SIZE = "REQUEST_LINE_ITEMS_PAYLOAD_SIZE",
  APP_INIT = "APP_INIT",
}

interface MetricActionData {
  value?: string;
  errored?: boolean;
}

const publishMetric = (
  action: MetricAction,
  { value, errored }: MetricActionData = {}
): void => {
  const attributes = value ? { attributes: { value } } : {};
  log({
    level: LogLevel.INFO,
    operationNamespace: "metric",
    action: errored ? `${action.toString()}_ERROR` : action.toString(),
    message: `Publishing metric ${action}`,
    ...attributes,
  });
};

export default publishMetric;
